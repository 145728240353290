@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  /* text-align: center; */
  font-family: 'Lato', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
img.background {
  height: 100%;
  width: 100%;
  position: absolute;
  pointer-events: none;
  z-index: -1;
  top: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.one-page {
  height: 100vh;
}

.full {
  height: 100%;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(13deg, #3f51b57a 14%, #3f51b57a 64%);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(13deg, #3f51b5 14%, #3f51b5 64%);
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: inset 7px 10px 12px #f0f0f0;
}
.centered {
  text-align: center;
}
.ctable {
  display: table;
  width: 100%;
  height: 100%;
}
.ctablecell {
  display: table-cell;
  vertical-align: middle;
}
.App .nav-bar .nav-item {
  padding: 0.6em 0.2em;
}

.App .nav-bar .nav-link.signin:hover {
  color: black !important;
  background-color: #ffffff99;
}

.App .navbar-light .navbar-nav .nav-link:hover {
  color: #ccc !important;
}

.App .nav-bar .nav-link.signin {
  background-color: #ffffff55;
  border-radius: 20px;
  padding: 5px 20px;
  margin: auto;
}

.header-title {
  font-weight: 500;
  font-size: 2.8rem;
  letter-spacing: -0.0625rem;
  line-height: 2.6rem;
  text-decoration: none;
  color: black;
}
.header-title-text {
  cursor: default;
  font-weight: 500;
  font-size: 3.2rem;
  letter-spacing: -0.0625rem;
  line-height: 2.6rem;
  text-decoration: none;
  color: black;
}
.header-title:hover {
  font-weight: 500;
  color: gray;
  text-decoration: none;
}
.App .leaguespartan {
  font-family: 'LeagueSpartanBold';
}
.App .btn-primary {
  background: #26a1ff;
  border-color: #26a1ff;
}
.App .header-dropdown {
  padding: 2em 2em 0 2em;
}
.App .org-dropdown {
  border-radius: 0.3em;
  padding: 1em 0.3em 1em 0.3em;
}

.App .dropdown-header {
  padding-left: 0px;
}

.table-vertical-align.table td {
  vertical-align: middle;
}
.App .tutorial-p {
  font-size: 12px;
  font-weight: 100;
  line-height: 15px;
  margin: 0;
}

.cursor {
  cursor: pointer;
}

.App .navbar-dark .navbar-nav .nav-link {
  color: white;
}
.App .navbar-dark .navbar-nav .nav-link:focus,
.App .navbar-dark .navbar-nav .nav-link:hover {
  color: #ffffff66;
}

.App .nav-link {
  font-weight: 700;
}

.App .navbar-light .navbar-nav .nav-link {
  color: #111;
}
.App .navbar-light .navbar-brand {
  color: #000;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App h1,
.App h2,
.App h3,
.App h4,
.App h5,
.App h6,
.App p,
.App .btn,
.App {
  font-family: 'Lato', sans-serif;
}

.App .main-font {
  font-family: 'LeagueSpartanBold';
}

.App .main-card {
  background-color: #ffffffdd;
  min-width: 600px;
}

.vertical-text {
  margin: auto;
  vertical-align: middle;
}

.nav-bar {
  z-index: 1;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App .navbar-brand {
  font-size: 1.3rem;
  font-family: 'LeagueSpartanBold', sans-serif;
  font-weight: 600;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.topofthetop {
  z-index: 100;
  position: fixed;
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

